import {get,post,del,put} from '../http/http'
import {qxtUrl} from "../http/baseUrl";

//企业需求
// 列表
export const getDemandList=(params)=> {
  return get(`${qxtUrl}/enterprise/request/list`,params);
}

//查询需求type
export const getDemandType=() => {
  return get(`${qxtUrl}/enterprise/request/type`);
}

//新增需求
export const addDemand = (data) => {
  return post(`${qxtUrl}/enterprise/request/submit`,data)
}

//删除需求
export const delDemand = (params) => {
  return del(`${qxtUrl}/enterprise/request/delete`,params);
}

// 专利匹配报告：/enterprise/patent/report
// 成果匹配报告：/enterprise/achievements/report
// 专家匹配报告：/enterprise/expert/report
export const getPatentMatch=(data) => {
  return get(`${qxtUrl}/enterprise/v2/patent/report`,data);
}
export const getResultsMatch=(data) => {
  return get(`${qxtUrl}/enterprise/v2/achievements/report`,data);
}
export const getExpertMatch=(data) => {
  return get(`${qxtUrl}/enterprise/v2/expert/report`,data);
}

// 词云
export const getWordCloud=(data) => {
  return get(`${qxtUrl}/enterprise/request/tag`,data);
}

//企业信息
export const getEnterpriseInfo=() => {
  return get(`${qxtUrl}/enterprise/getEnterpriseInfo`)
}


//企业需求报告
export const getDemandReport=(data) => {
  return get(`${qxtUrl}/enterprise/exportRequestReport`,data)
}

// 信息匹配
export const getInfoReport=() => {
  return get(`${qxtUrl}/enterprise/exportEnterpriseReport`)
}