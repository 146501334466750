<template>
  <div>
    <div v-if="nowIndex === 0">
      <div class="right_header" style="position: relative">
        <div class="dp-flex query-form">
          <div class="flex-align-center">
            <span>需求名称：</span>
            <Input v-model="params.title" style="height:32px;width:200px" />
          </div>
          <div class="flex-align-center">
            <span>需求提列时间：</span>
            <div>
                <DatePicker
                  type="date"
                  :options="startOption"
                  :transfer="true"
                  v-model="startTime"
                  ref="startTime"
                  style="width:150px"
                ></DatePicker>
                <span>至</span>
                <DatePicker
                  type="date"
                  :options="endOption"
                  :transfer="true"
                  ref="endTime"
                  v-model="endTime"
                  style="width:150px"
                ></DatePicker>
            </div>
          </div>
          <div class="flex-align-center">
            <span>需求类型：</span>
            <Select
              v-model="params.requirementType"
              :transfer="true"
              style="width:150px"
            >
              <!--              <option value="0">委员会评审</option>-->
                <Option value="">全部</Option>
                <Option
                    v-for="(option, index) in optionsList"
                    :value="option.requirementType"
                    :key="index"
                >{{ option.requirementTypeName }}</Option
                >

            </Select>
          </div>
          <div class="flex-align-center">
            <div class="button_style" style="background-color:#2d8cf0;margin-left: 13px" @click="search()">
              检索
            </div>
            <div
              class="button_style"
              style="background-color: #7E73E7;margin-left: 13px"
              @click="addDemand()"
            >
              +新增需求
            </div>
          </div>
        </div>
      </div>
      <div class="right_real_content" style="position: relative">
        <Table
          border
          :columns="columns12"
          :data="dataList"
          :disabled-hover="true"
        >
          <template slot-scope="{ row, index }" slot="action">
            <div v-if="row.requirementType === 'TECHNICAL_AUTOMATIC_MATCHING'">
              <Button
                type="text"
                size="small"
                style="margin-right: 5px; color: rgb(45, 140, 240)"
                @click="viewReport(row)"
                >查看报告
              </Button>
              <span class="operation_span"></span>
              <Button
                type="text"
                size="small"
                @click="deleteDemand(row)"
                style="color: #f16643"
                >删除需求</Button
              >
            </div>
            <div v-if="row.requirementType !== 'TECHNICAL_AUTOMATIC_MATCHING'">
              <Button
                v-if="row.replayStatus == '已回复'"
                type="text"
                size="small"
                style="margin-right: 5px; color: rgb(45, 140, 240)"
                @click="seeReplay(row)"
                >查看回复
              </Button>
              <span class="operation_span"  v-if="row.replayStatus == '已回复'">|</span>
              <Button
                type="text"
                size="small"
                @click="deleteDemand(row)"
                style="color: #f16643"
                >删除需求</Button
              >
            </div>
          </template>
        </Table>
        <Page
          :total="total"
          show-total
          show-sizer
          style="margin-top: 50px; float: right;margin-bottom: 20px"
          @on-change="changePage"
        />
      </div>
    </div>

    <div v-if="nowIndex === 1">
      <Tabs @on-click="onChangeTab">
        <TabPane label="推荐科研专家">
          <div class="neirong">
            <div class="nei_rong_box">
              <div class="flex-wrap">
                <div class="result-expert-hover result-hover" v-for="(item, index) in expertList" :key="index">
                  <div style="height:16px;background:#1767E0;border-radius: 8px 8px 0 0;"></div>
                  <div style="padding: 30px 30px 10px 30px">
                    <div class="flex-column flex-align-center" style="border-bottom: 1px dashed #D5E7FA;padding-bottom:20px">
                      <span style="font-size: 24px;">{{ item.expertName }}</span>
                      <span style="font-size: 16px">{{ item.unitsName }}</span>
                    </div>
                    <div class="flex-column flex-align-center" style="padding-top:20px">
                      <div>
                        推荐指数：<span>{{ item.expertsCount }}</span>
                      </div>
                      <div style="height: 42px;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                        <span>
                          技术关键词：{{item.keywords || '暂无'}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div @click.stop="openZoosUrl" class="contact-btn">
                    联系专家
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Page
            :total="expertTotal"
            show-elevator
            :current.sync='matchParams.pageNum'
            style="margin-top: 50px; float: right;margin-bottom: 20px"
            @on-change="changePageE"
          />
        </TabPane>
        <TabPane label="推荐科研成果">
          <div class="neirong">
            <div class="nei_rong_box">
              <div class="flex-wrap">
                <div @click="seeResult(item)" class="result-ach-hover result-hover flex-col-between" v-for="(item, index) in resultList" :key="index">
                  <div>
                    <div style="padding-left:10px;border-bottom:1px dashed #D5E7FA;margin-bottom:20px">
                      <div>
                        <span style="color:#333333;font-size:18px">{{ item.title }}</span>
                      </div>
                      <div class="flex-align-center" style="margin:20px 0">
                        <span style="font-size: 14px;background-color: #fcdfc8;color:#fea464;padding:5px;border-radius:4px;margin-right:20px;width: 68px;display: inline-block;">{{
                          item.achieveType
                        }}</span>
                        <span style="margin-right:20px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;line-height: 25px;text-align: left;color:#333333"><span style="color:#999999;">归属高校：</span>{{ item.colleges }}</span>
                        <span style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;line-height: 25px;text-align: left;color:#1767E0"><span style="color:#999999;">应用方向：</span>{{ item.category }}</span>
                      </div>
                    </div>
                    <div style="height: 42px;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      技术关键词：{{ item.keywords }}
                    </div>
                  </div>
                  <div class="contact-btn" @click.stop="openZoosUrl">
                    联系成果
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Page
            :total="resultTotal"
            show-elevator
            :current.sync='matchParams.pageNum'
            style="margin-top: 50px; float: right;margin-bottom: 20px"
            @on-change="changePageR"
          />
        </TabPane>
        <TabPane label="推荐科研专利">
          <div class="neirong">
            <div class="nei_rong_box" style="padding-bottom: 30px" >
              <div class="flex-wrap">
                <div @click="seePatent(item)" class="result-patent-hover result-hover" v-for="(item, index) in patentList" :key="index">
                  <div>
                    <div style="color:#333333;font-size:18px">
                      <span>{{ item.ti }}</span>
                    </div>
                    <div style="margin:10px 0">
                      <span style="font-size: 14px;background-color: #fcdfc8;color:#fea464;padding:5px 10px;border-radius:4px;margin-right:20px">{{ item.patType }}</span>
                      <span style="font-size: 14px;background-color: #cadffe;color:#0f62df;padding:5px 10px;border-radius:4px;">{{ item.lastLegalStatus }}</span>
                    </div>
                    <div style="border-top:1px dashed #D5E7FA;margin-top:20px; color:#666666">
                      <div style="margin-top:17px">
                        <span>专利申请号：</span><span>{{ item.an }}</span>
                      </div>
                      <div style="margin-top:17px">
                        <span class="ellipse-2">归属高校：{{ item.pa }}</span>
                      </div>
                      <div style="margin-top:17px">
                        <span style="height:42px" class="ellipse-2">成果发明人：{{ item.pin }}</span>
                      </div>
                    </div>
                  </div>
                  <div @click.stop="openZoosUrl" class="contact-btn">
                    联系专利
                  </div>
                </div>
              </div>
            </div>
          </div>
            <Page
            :total="patentTotal"
            show-elevator
            style="margin-top: 50px; float: right;margin-bottom: 20px"
            @on-change="changePageP"
            :current.sync='matchParams.pageNum'
          />
        </TabPane>
        <TabPane label="需求报告">
          <div class="report-con dp-flex">
            <div class="report-info">
              <div class="report-title">基础信息</div>
              <div class="report-items">
                <div>
                  <span>需求标题：</span>
                  <span>{{ detailItem.title }}</span>
                </div>
                <div>
                  <span>需求日期：</span>
                  <span>{{ detailItem.createdTime }}</span>
                </div>
                <div>
                  <span>需求类型：</span>
                  <span>{{ detailItem.requirementTypeName }}</span>
                </div>
                <div>
                  <span>需求描述：</span>
                  <span>{{ detailItem.content }}</span>
                </div>
              </div>
            </div>
            <div class="report-info">
              <div class="report-title">热词频率</div>
              <div
                style="height: 370px; width:350px"
                ref="wd"
              ></div>
            </div>
          </div>
          <div style="padding: 20px 0px 30px 0px; text-align:center">
            <div @click="getDemandReport" style="width:220px;height:60px;line-height:60px;border-radius: 10px;display:inline-block;margin:0 auto;background-color: #1767E0;cursor: pointer;text-align: center;font-size: 24px;color: white;">
              下载报告
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <Modal
      id="ReplayModal"
      v-model="ReplayModal"
      title="回复详情"
      width="800"
      :footer-hide="footHide"
    >
      <div>
        <div
          id="replay"
          style="
            background-color: rgb(245, 246, 250);
            position: relative;
            padding: 20px;
            margin-bottom: 50px;
          "
        >
          <div style="font-weight: bold; margin-left: 20px">回复内容：</div>
          <div
            style="
              width: 90%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <span v-if="replayItem.replyContent">
              {{ replayItem.replyContent }}
            </span>
            <span v-else> 尚未回复 </span>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="deleteModal"
      title="删除需求"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 30px">
              <span>您确定删除该需求？</span>
            </div>
            <div>
              <Button
                style="
                  margin-left: 26px;
                  position: relative;
                  top: 50%;
                "
                @click="delCancel()"
              >
                我再想想
              </Button>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="confirmDel()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="addModal"
      ok-text="提交"
      cancel-text="取消"
      width="800"
      title="新增需求"
    >
      <div
        style="margin-top: 40px; margin-bottom: 40px; border-bottom: none"
        class="div_center"
      >
        <Form :model="addDemandForm" :label-width="100">
          <FormItem label="需求类型:">
            <CheckboxGroup
              v-model="addDemandForm.demandType"
              @on-change="dataChange"
            >
              <Checkbox
                :label="item.requirementTypeName"
                v-for="(item, index) in optionsList"
                :key="index"
              ></Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="需求标题:">
            <Input
              v-model="addDemandForm.title"
              placeholder="请输入需求标题"
            ></Input>
          </FormItem>
          <FormItem label="需求内容:">
            <Input
              placeholder="请输入需求内容"
              v-model="addDemandForm.content"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
            ></Input>
          </FormItem>
          <FormItem label="需求标签:">
            <Input
              v-model="addDemandForm.label"
              placeholder="请输入需求标签"
            ></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: left; margin-top: 30px; margin-left: 60px">
        <div
          class="button_style"
          style="margin-left: 26px; width: 120px; position: relative; top: 50%"
          @click="submitAdd()"
        >
          提交需求
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getDemandList,
  getDemandType,
  addDemand,
  delDemand,
  getExpertMatch,
  getPatentMatch,
  getResultsMatch,
  getWordCloud,
} from "../../../plugins/api/EnterpriseSchoolConnection";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
  errorToast,
} from "@/plugins/tools/util";
import axios from "axios";
import {qxtUrl} from "../../../plugins/http/baseUrl";
import {getLocalToken} from "../../../plugins/tools/storage";
export default {
  name: "EnterpriseDemand",
  watch: {
    startTime() {
      let self = this;
      this.endOption = {
        disabledDate(date) {
          if (self.startTime) {
            return date && date.valueOf() < self.startTime;
          }
        },
      };
    },
    endTime() {
      let self = this;
      this.startOption = {
        disabledDate(date) {
          if (self.endTime) {
            return date && date.valueOf() > self.endTime;
          }
        },
      };
    },
  },

  mounted() {
    this.getDemand();
    this.getDemandTypeList();
  },

  data() {
    return {
      requestId: '',
      nowIndex: 0,
      replayItem: {}, // 回复的item
      deleteId: "",
      total: 10,
      matchParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        startTime: "",
        endTime: "",
        requirementType: "",
      },
      optionsList: [],
      addModal: false,
      deleteModal: false,
      ReplayModal: false,
      startOption: {},
      endOption: {},
      startTime: "",
      endTime: "",
      footHide: false,
      data7: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
      ],
      cloudData: [
      ],
      addDemandForm: {
        demandType: [],
        title: "",
        content: "",
        label: "",
      },
      addDemandFormSecond: {
        demandType: [],
        title: "",
        content: "",
        label: "",
      },
      patentTotal: 0,
      patentNow: 1,
      resultTotal: 0,
      resultNow: 1,
      expertTotal: 0,
      expertNow: 1,
      patentList: [],
      resultList: [],
      expertList: [],
      dataList: [],
      detailItem: {},
      columns12: [
        {
          title: "需求时间",
          key: "createdTime",
          align: "center",
        },
        {
          title: "需求标题",
          key: "title",
          align: "center",
        },
        {
          title:"回复状态",
          key:'replayStatus',
          align: 'center'
        },
        {
          title: "需求类型",
          key: "requirementTypeName",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
    };
  },
  methods: {
    expertDetail(item){
      let routeUrl = this.$router.resolve({
        path: "/home/experts",//新页面地址
        query: { shopId: item.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    async backPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === 1) {
            return "";
          } else {
            this.resultNow -= 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code == 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === 1) {
            return "";
          } else {
            this.expertNow -= 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code == 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === 1) {
            return "";
          } else {
            this.patentNow -= 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code == 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
      }
    },
    async forwardPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === this.resultTotal) {
            return "";
          } else {
            this.resultNow += 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code == 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === this.resultTotal) {
            return "";
          } else {
            this.expertNow += 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code == 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === this.patentTotal) {
            return "";
          } else {
            this.patentNow += 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code == 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
      }
    },
    changePageE(index) {
      this.onChangePage('expert', index)
    },
    changePageR(index) {
      this.onChangePage('result', index)
    },
    changePageP(index) {
      this.onChangePage('patent', index)
    },
    async onChangePage(name, index) {
      switch (name) {
        case "result":
          var rjson = await getResultsMatch({ ...this.matchParams, pageSize: 9 });
          if (rjson.code == 0) {
            this.resultList = rjson.result.list;
            this.resultTotal = rjson.result.total;
          } else {
            errorToast(rjson.message);
          }
          break;
        case "expert":
          var rjson = await getExpertMatch({ ...this.matchParams, pageSize: 10 });
          if (rjson.code == 0) {
            this.expertList = rjson.result.list;
            this.expertTotal = rjson.result.total;
          } else {
            errorToast(rjson.message);
          }
          break;
        case "patent":
          var rjson = await getPatentMatch({ ...this.matchParams, pageSize: 9 });
          if (rjson.code == 0) {
            this.patentList = rjson.result.list;
            this.patentTotal = rjson.result.total;
          } else {
            errorToast(rjson.message);
          }
          break;
      }
    },
    onChangeTab() {
      this.matchParams.pageNum = 1
    },
    // 画词云
    drawCloud(wrapEl, data) {
      let myChart = this.$echarts.init(wrapEl);
      var option = {
        tooltip: {
          show: true,
        },
        series: [
          {
            name: "热词",
            type: "wordCloud",
            sizeRange: [10, 30],
            rotationRange: [-20, 20],
            shape: "circle",
            left: "center",
            top: "center",
            width: "100%",
            height: "100%",
            gridSize: 3,
            textPadding: 0,
            autoSize: {
              enable: true,
              minSize: 6,
            },
            textStyle: {
              normal: {
                color: function () {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                    ].join(",") +
                    ")"
                  );
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 多选边单选
    dataChange(data) {
      if (data.length > 1) {
        this.addDemandForm.demandType = [];
        let index = String(data.length - 1);
        this.addDemandForm.demandType = [data[index]];
      }
    },
    async getDemandReport(){
      let json = await axios({
        method: "get",
        url: `${qxtUrl}/enterprise/exportRequestReport?requestId=${this.requestId}`,
        data: this.params,
        responseType: "arraybuffer",
        headers: { token: getLocalToken() },
      });
      let fileName = "需求报告.pdf";
      if(json.status == 200){
        let blob = new Blob([json.data], {
          type: "application/vnd.ms-excel};charset=utf-8",
        });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }else{
        errorToast(json.message)
      }
    },
    changeOption() {},
    addDemand() {
      this.addModal = true;
    },
    // 提交需求
    async submitAdd() {
      this.optionsList.forEach((item, index) => {
        if (item.requirementTypeName === this.addDemandForm.demandType[0]) {
          this.addDemandForm.demandType[0] = item.requirementType;
        }
      });
      let submitForm = {};
      submitForm.requirementType = this.addDemandForm.demandType[0];
      submitForm.title = this.addDemandForm.title;
      submitForm.content = this.addDemandForm.content;
      submitForm.label = this.addDemandForm.label;
      if (!this.addDemandForm.content) {
        warnToast("请填写需求内容");
        return;
      }
      if (!this.addDemandForm.demandType) {
        warnToast("请填写需求类型");
        return;
      }
      if (!this.addDemandForm.label) {
        warnToast("请填写需求标签");
        return;
      }
      if (!this.addDemandForm.title) {
        warnToast("请填写需求标题");
        return;
      }
      let json = await addDemand(submitForm);
      if (json.code === 0) {
        successToast("添加成功");
        this.getDemand();
      } else {
        warnToast(json.message);
      }
      this.addModal = false;
      for(let item in this.addDemandForm){
        if(item != 'demandType'){
        this.addDemandForm[item] = ""
        }else{
          this.addDemandForm[item] = [];
        }
      }
    },

    //得到类型列表
    async getDemandTypeList() {
      let json = await getDemandType();
      if (json.code === 0) {
        this.optionsList = JSON.parse(JSON.stringify(json.result));

      }
    },

    // 搜索
    async search() {
      this.params.pageNum = 1;
      debugger
      if (this.startTime) {
        this.params.startTime = this.startTime.getTime();
      } else {
        this.params.startTime = "";
      }
      if (this.endTime) {
        this.params.endTime = this.endTime.getTime();
      } else {
        this.params.endTime = "";
      }
      this.getDemand();
    },

    //得到需求列表
    async getDemand() {
      let json = await getDemandList(this.params);
      if (json.code === 0) {
        this.dataList = json.result.list;
        this.dataList.forEach((item, index) => {
          if(item.requirementType == 'TECHNICAL_AUTOMATIC_MATCHING'){
            item.replayStatus = '--'
          }else{
            if(item.replyContent){
              item.replayStatus = "已回复"
            }else{
              item.replayStatus = "未回复"
            }
          }

          let dateee = new Date(item.createdTime).toJSON();
          item.createdTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "");
        });
        this.total = json.result.total
      }
      this.$parent.changeHeight();

    },

    // 翻页
    async changePage(index) {
      this.params.pageNum = index;
      await this.getDemand();
    },

    // 删除
    deleteDemand(item) {
      this.deleteId = item.id;
      this.deleteModal = true;
    },
    delCancel() {
      this.deleteModal = false;
    },
    async confirmDel() {
      let data = {
        ids: this.deleteId,
      };
      let json = await delDemand(data);
      if (json.code === 0) {
        successToast("删除成功");
        this.getDemand();
      } else {
        warnToast(json.message);
      }
      this.deleteModal = false;
    },
    color: function () {
      return (
          "rgb(" +
          [
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
          ].join(",") +
          ")"
      );
    },
    async viewReport(row) {
      this.detailItem = row;
      this.matchParams.requestId = row.id;
      this.requestId = row.id
      let json = await getPatentMatch({...this.matchParams, pageSize: 9 });
      if (json.code == 0) {
        this.patentList = json.result.list;
        this.patentTotal = json.result.total;
      } else {
        errorToast(json.message);
      }

      let rjson = await getResultsMatch({ ...this.matchParams, pageSize: 9 });
      if (rjson.code == 0) {
        this.resultList = rjson.result.list;
        this.resultTotal = rjson.result.total;
      } else {
        errorToast(rjson.message);
      }
      let ejson = await getExpertMatch({ ...this.matchParams, pageSize: 10 });
      if (ejson.code == 0) {
        this.expertList = ejson.result.list;
        this.expertTotal = ejson.result.total;
      } else {
        errorToast(ejson.message);
      }

      let wordCloudList = await getWordCloud({ requestId: row.id });
      if(wordCloudList.code == 0){
        if(wordCloudList.result.length > 0){
          wordCloudList.result.forEach((item,index) => {
            this.cloudData.push( { value: Math.round(Math.random() * 250), name: item.name, textStyle: { color: this.color() } })
          })
        }else{
          this.cloudData.push( { value: Math.round(Math.random() * 250), name: "无", textStyle: { color: this.color()}})
        }
      }

      this.nowIndex = 1;
      this.$parent.changeHeight();
      this.$nextTick(()=> {
        this.drawCloud(this.$refs.wd,this.cloudData)
      })

      scrollTo(0, 0);
    },
    seePatent(item){
      let routeUrl = this.$router.resolve({
        path: "/home/ResourceDetail",//新页面地址
        query: { an: item.an }//携带的参数
      });
      window.open(routeUrl.href, "_blank");

    },
    seeResult(item){
      let routeUrl = this.$router.resolve({
        path: "/home/PatentSearchDetail",//新页面地址
        query: { id: item.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    seeReplay(row) {
      this.replayItem = row;
      this.ReplayModal = true;
      this.requestId = row.id;
    },
  },
};
</script>

<style scoped lang="scss">
/*/deep/ .ivu-modal-header-inner {*/
/*text-align: center;*/
/*background-color: rgb(245, 246, 250)!important;*/
/*}*/

/*/deep/ .ivu-modal-header {*/
/*background-color: rgb(245, 246, 250)!important;*/
/*}*/
/*/deep/ .ivu-modal-footer {*/
/*display: none!important;*/
/*}*/
/*/deep/ .ivu-rate-text {*/
/*display: none;*/
/*}*/

/deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  line-height: 40px !important;
}


.button_style {
  display: inline-block;
  background-color: #2d8cf0;
  z-index: 51;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  padding: 0 27px;
  line-height: 32px;
  height: 32px;
  border-radius: 4px;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.right_real_content {
  margin: 50px 20px;
  overflow: hidden;
  padding-bottom: 10px;
}

.ivu-select-selection {
  border-radius: 0 !important;
}
.arrow_ld {
  display: inline-block;
  position: absolute;
  right: 0;
  & > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: #cecece;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  & > span:hover{
    background-color: #37c5cf;
  }
}
.qiye,
.qiye td {
  border: 1px solid #1767E0;
}
.qiye {
  border-bottom: none;
  border-right: none;
  width: 100%;
}
.qiye td {
  border-top: none;
  border-left: none;
  text-align: center;
}
.span_yyjs {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  width: 64px;
  border-width: 1px;
  background-color: #fcdfc8;
  border-color: #fea464;
  border-style: solid;
  padding: 2px;
  color: black;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  right: 20px;
}
.qiye td:nth-child(1) {
  width: 100px;
  text-align: right;
}

.qiye td:nth-child(2) {
  width: 310px;
}

.qiye td:nth-child(3) {
  width: 130px;
}

.qiye td:nth-child(4) {
  width: 260px;
}

.span_cg {
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.common_liucheng {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #dfe0f2 !important;
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_header {
  padding: 20px;
  margin-bottom: 40px !important;
  border-bottom: 1px solid #e8eaec;
  overflow: hidden;
  span {
    color: #597697;
    font-size: 14px;
  }
  .query-form {
    >div{
      margin-right: 10px;
    }
  }
}
.neirong {
  padding: 20px;
  background: #f6faff;
  .contact-btn {
    display: block;
    height: 40px;
    line-height: 40px;
    color: #1767E0;
    text-align: center;
    background: #D5E7FA;
    visibility: hidden;
    border-radius: 0 0 8px 8px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  .result-expert-hover {
    flex: 19%;
    margin: 5px;
    border-radius: 8px;
  }
  .result-ach-hover{
    flex: 32%;
    margin: 5px;
    border-radius: 8px;
  }
  .result-patent-hover{
    flex: 32%;
    margin: 5px;
    border-radius: 8px;
  }
}
.report-con {
  padding: 20px;
  .report-info {
    box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    width: 49%;
    height: 370px;
    margin:0 10px;
  }
  .report-title{
    font-size: 16px;
    line-height: 16px;
    height: 14px;
    color: #333333;
    padding-left: 10px;
    border-left: 6px solid #1767E0;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .report-items {
    >div{
      margin-bottom: 15px;
      margin-left: 20px;
    }
  }
}
/deep/ .ivu-tabs-bar {
  border-bottom: 1px solid #dcdee2 !important;
  margin-bottom: 0;
  box-shadow: 0px 1px 0px 0px #DFE6EC;
}
/deep/ .ivu-tabs-tab {
  padding:15px 0 !important;
  margin-right: 35px!important;
}
/deep/ .ivu-tabs-nav-wrap {
  padding-left: 30px;
}
/deep/ .ivu-input {
  height: 32px !important;
  border-radius: 4px !important;
}
/deep/ .ivu-select-selection {
  height: 32px !important;
  border-radius: 4px !important;
}
/deep/ .ivu-tabs-tabpane {
  background: #f6faff;
}

/deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder {
  line-height: 32px!important;
}

/deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  line-height: 32px!important;
}

.result-hover {
  background: #fff;
  box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
  position: relative;
  &:not(.result-expert-hover) {
    padding: 20px 30px 40px 30px;
  }
  &.result-expert-hover{
    .contact-btn{
      position: static;
    }
  }
  &:hover {
    .contact-btn {
      visibility: visible;
    }
  }
}
</style>
